import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { FormControl, NgModel } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-multi-search-select',
  templateUrl: './multi-search-select.component.html',
  styleUrls: ['./multi-search-select.component.css']
})
export class MultiSearchSelectComponent implements OnInit, OnChanges {

  @Input('formControl') formControl;
  @Input('placeholder') placeholder;
  @Input('list') list;
  @Input('structured') structured;
  @ViewChild('multiSelect') singleSelect;

  filterCtrl: FormControl = new FormControl();

  public filteredList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject();

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges) {

    // detect changes on list and update it

    if (changes && changes.list.currentValue) {
      this.list = changes.list.currentValue;
      this.filteredList$.next(this.list.slice());
    }
  }

  ngOnInit() {
    // load the initial list
    this.filteredList$.next(this.list.slice());

    // listen for search field value changes
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterList();
      });
  }

  private filterList() {
    if (!this.list) {
      return;
    }
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filteredList$.next(this.list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredList$.next(
      this.list.filter(item => item.name.toLowerCase().indexOf(search) > -1)
    );
  }

  selectionChange(event) {
    if (this.structured && event.isUserInput) {
      const parent = this.list.find(item => item.id === event.source.value);
      if (event.source.selected === true) {
        if (parent && parent['children'] && parent['children'].length > 0) {
          const oldValue = this.formControl.value ? this.formControl.value : [];
          parent['children'].forEach(child => {
            oldValue.push(child['id']);
          });
          this.formControl.setValue(oldValue);
          this.cdr.detectChanges();
        }
      }
    }
  }

  isPartlyChecked(item) {
    if (this.formControl && this.formControl.value) {
      return !item.children.every(child => this.formControl.value.indexOf(child.id) > -1);
    }
    return true;
  }

}
