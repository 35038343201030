import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-caption',
  templateUrl: './caption.component.html',
  styleUrls: ['./caption.component.css']
})
export class CaptionComponent implements OnInit {

  form: FormGroup;
  stockStatuses = [];
  constructor(
    public dialogRef: MatDialogRef<CaptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private fb: FormBuilder,
  ) {
    this.data = data;
    this.bindForm();
  }

  ngOnInit() {


  }

  bindForm() {
    this.form = this.fb.group({
      caption: [this.data],
    });

  }

  prepareCaptionInfo() {
    this.data = this.form.controls['caption'].value;
  }


  close() {
    this.prepareCaptionInfo();
    this.dialogRef.close(this.data);
  }
  save() {
    this.prepareCaptionInfo();
    this.dialogRef.close(this.data);
  }




}
