import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AbstractControl} from '@angular/forms';

@Component({
  selector: 'app-phone-input',
  templateUrl: './phone-input.component.html',
  styleUrls: ['./phone-input.component.css']
})
export class PhoneInputComponent implements OnInit {

  @Input('formControl') formControl: AbstractControl;
  @Input('defaultCountry') defaultCountry;
  @Input('required') required;
  @ViewChild('international_phone') phone;

  constructor() {
  }

  ngOnInit() {
    this.subscribePhoneChange();
  }

  subscribePhoneChange() {
    this.formControl.valueChanges.subscribe(value => {
      if (value) {
        value = value.trim();
      }
      if (value && value.length >= 3 && value.substring(0, 2) === '00') {
        this.phone.defaultCountry = '';
        this.formControl.setValue('+' + value.substring(2, value.length));
      }
    });
  }
}
