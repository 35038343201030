import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {FormGroup} from '@angular/forms';

export enum ErrorTypes {
  Required = 'required',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Email = 'email',
  Pattern = 'pattern'
}


@Component({
  selector: 'app-errors',
  templateUrl: './errors.component.html',
  styleUrls: ['./errors.component.css']
})
export class ErrorsComponent implements OnInit {
  @Input('form') public form: FormGroup;
  @Input('errorsList') public errorsList;
  @Input('label') public label: string;
  @Input('property') public property: string;
  errorTypes = ErrorTypes;


  constructor() {
  }

  ngOnInit() {
  }

}
