import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslatePipe} from './translate.pipe';
import {TRANSLATION_PROVIDERS} from './translation';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [TranslatePipe],
  exports: [TranslatePipe],
  providers: [TRANSLATION_PROVIDERS]
})
export class TranslateSharedModule { }
