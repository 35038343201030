import { Component, EventEmitter, Input, OnInit, Output, Inject, ViewChild } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper/src/image-cropper.component';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { api } from '../../../../config/api';
import { MessageService } from '../../services/message.service';
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-clic-image-v2',
  templateUrl: './clic-image.component.html',
  styleUrls: ['./clic-image.component.css']
})
export class ClicImageV2Component implements OnInit {

  @Output('imageUrl') imageUrl = new EventEmitter();
  @Input() width = 0;
  @ViewChild('fileInput') fileInput;
  oldImageUrl: any = '';
  imageChangedEvent: any = null;
  croppedImage: ImageCroppedEvent = null;
  progressValue: number;

  constructor(private http: HttpClient, private messageService: MessageService,
    public dialogRef: MatDialogRef<ClicImageV2Component>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.oldImageUrl = this.data['oldImageUrl'];
  }

  ngOnInit() {
    if (!this.oldImageUrl) {
      this.fileInput.nativeElement.click();
    }
  }

  fileChangeEvent(event: any): void {
    this.oldImageUrl = '';
    this.progressValue = 0;
    this.imageChangedEvent = event;
  }

  onUpload() {
    if (!this.croppedImage) {
      return;
    }
    const uploadData = new FormData();
    uploadData.append('photo', this.croppedImage.file);
    this.http.post(api.UPLOAD_PHOTO, uploadData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressValue = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {

          this.dialogRef.close(event.body['data'].url);
        }
      })
    ).subscribe();
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }

  cancel() {
    this.dialogRef.close();
  }
  // private changeProfilePicture(url) {
  //   const request = {ids: [this.id], attribute: this.attribute, value: url};
  //   return this.http.post(this.endPoint, request);
  // }
}
