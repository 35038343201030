import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper/src/image-cropper.component';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { api } from '../../../../config/api';
import { MessageService } from '../../services/message.service';
import { map } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-clic-image',
  templateUrl: './clic-image.component.html',
  styleUrls: ['./clic-image.component.css']
})
export class ClicImageComponent implements OnInit {

  @Output('imageUrl') imageUrl = new EventEmitter();
  @Input() width = 0;
  imageChangedEvent: any = null;
  croppedImage: ImageCroppedEvent = null;
  progressValue: number;
  aspectRatio = 4 / 3;
  format = 'jpeg';

  constructor(private http: HttpClient, private messageService: MessageService,
    public dialogRef: MatDialogRef<ClicImageComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imageChangedEvent = data['file'];
    this.aspectRatio = data['aspectRatio'];
    this.format = data['format'];
  }

  ngOnInit() {
  }

  onUpload() {
    if (!this.croppedImage) {
      return;
    }
    this.dialogRef.close(this.croppedImage.file);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event;
  }

  imageLoaded() {
    // show cropper
  }

  loadImageFailed() {
    // show message
  }
}
