import { Injectable } from '@angular/core';
import { api } from '../../../../config/api';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Page } from './models/page';

@Injectable({
  providedIn: 'root'
})
export class PageService {
  builder: any;
  constructor(private http: HttpClient) {
  }

  pagesBuilder() {
    return this.http.get(api.PAGES_BUILDER);
  }


  getPage(page: number) {
    if (page === 0) {
      return of(null);
    }
    const url = `${api.PAGES_GET}/${page}`;
    return this.http.get(url);
  }

  getPageByIdent(page: String) {
    if (!page) {
      return of(null);
    }
    const url = `${api.PAGES_IDENT_GET}/${page}`;
    return this.http.get(url);
  }

  editPageByIdent(page: Page, ident: String): Observable<{}> {
    const url = `${api.PAGES_IDENT_UPDATE}/${ident}`;
    return this.http.put(url, page);
  }


  editPage(page: Page, id: number): Observable<{}> {
    const url = `${api.PAGES_UPDATE}/${id}`;
    return this.http.put(url, page);
  }
}
