export const LANG_EN_NAME = 'en';

export const LANG_EN_TRANS = {
  'first_name': 'First Name',
  'last_name': 'Last Name',
  'father_name': 'Father Name',
  'mother_name': 'Mother Name',
  'gender': 'Gender',
  'title': 'Title',
  'date_of_birth': 'Date Of Birth',
  'country_of_birth': 'Country Of Birth',
  'place_of_birth': 'Place Of Birth',
  'marital_status': 'Marital Status',
  'email': 'Email',
  'mobile': 'Mobile',
  'password': 'Password',
  'work_status': 'Work Status',
  'profession_id': 'Profession ID',
  'job_title': 'Job Title',
  'profile_photo': 'Profile Photo',
  'active': 'Active',
  'nationalities': 'Nationalities',
  'name': 'Name',
  'number': 'Number',
  'type': 'Type',
  'ext': 'EXT'
};

export const LANG_EN_DIR = 0;
