import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AuthService} from '../../modules/auth/services/auth.service';
import {client} from '../../../config/client';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.authService.me().pipe(map(response => {
        this.authService.user = response;
        this.router.navigate([client.DASHBOARD]).then();
        return false;
      }),
      catchError(() => {
        return of(true);
      })
    );
  }
}

