import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import 'rxjs-compat/add/operator/map';
import { Validators } from '@angular/forms';
import { PageService } from '../page.service';

@Injectable()
export class PageBuilderResolver implements Resolve<any> {

  sections = {};
  isRequiredField = {};
  constructor(private pageService: PageService) {
  }

  PreProcessPagesForm() {
    this.pageService.builder['sections'].forEach((section) => {
      if (section.enable) {
        this.pageService.builder[section.name] = {};
        section.fields.forEach(field => {
          if (field.enable) {
            let defaultValue: any = '';
            if (field.type === 'checkbox') {
              defaultValue = false;
            }
            this.pageService.builder[section.name][field.name] = [defaultValue, this.pushValidations(field)];
          }
        });
      }
    });
  }

  processValidationIntoAngularSyntax(validationItem, field) {
    if (validationItem === 'required' || field.required) {
      this.isRequiredField[field.name] = true; return Validators.required;
    } else {
      this.isRequiredField[field.name] = false;
    }
    if (validationItem === 'integer') { return Validators.pattern('^[0-9]*$'); }
    if (validationItem.includes('min') && validationItem.split(':').length > 1) { return Validators.minLength(validationItem.split(':')[1]); }
    if (validationItem.includes('max') && validationItem.split(':').length > 1) { return Validators.maxLength(validationItem.split(':')[1]); }
  }

  pushValidations(field) {
    const returnedValidations: any = [];
    let validation;
    if (field.validations) {
      const validationArr = field.validations.split('|');
      if (validationArr && validationArr.length > 0) {
        validationArr.forEach(validationItem => {
          validation = this.processValidationIntoAngularSyntax(validationItem, field);
        });
      }
    } else {
      validation = this.processValidationIntoAngularSyntax('', field);
    }
    if (validation) {
      returnedValidations.push(validation);
    }
    return returnedValidations;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return this.pageService.pagesBuilder().map(builder => {
      this.pageService.builder = builder;
      this.pageService.builder.isRequiredField = this.isRequiredField;
      this.PreProcessPagesForm();
    });
  }
}
