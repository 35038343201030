import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../modules/auth/services/auth.service';
import { catchError, map } from 'rxjs/operators';
import { client } from '../../../config/client';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanLoad, CanActivate {
  constructor(private authService: AuthService, private router: Router) {
  }

  canLoad(route: Route): Observable<boolean> | boolean {
    return this.check();
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    return this.check();
  }

  private check(): Observable<boolean> | boolean {
    if (this.authService.user) {
      return true;
    } else {
      return this.authService.me().pipe(map(response => {
          this.authService.user = response;
          return true;
        }),
        catchError(() => {
          this.router.navigate([client.LOGIN]).then();
          return of(false);
        })
      );
    }
  }
}
