import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MessageService } from '../services/message.service';
import { TokenService } from '../services/token.service';
import { AuthService } from '../../modules/auth/services/auth.service';
import { MatDialog } from '@angular/material';
import { client } from '../../../config/client';

@Injectable({
  providedIn: 'root'
})
export class ServerErrorHandlerService {

  constructor(private router: Router, private messageService: MessageService,
    private authService: AuthService, private dialogsRef: MatDialog) {
  }

  public handleError(httpResponse) {
    if (httpResponse instanceof HttpErrorResponse) {
      console.log(httpResponse);
      if (!navigator.onLine) {
        this.messageService.display('No Internet Connection.', 'danger');
        return {};
      } else if (httpResponse.status === 401) {
        this.dialogsRef.closeAll();
        TokenService.logOut();
        this.router.navigate([client.LOGIN]).then();
        return null;
      } else if (httpResponse.error['errors']) {
        return httpResponse.error['errors'];
      }
    }
    if (httpResponse.error && httpResponse.error.message) {
      this.messageService.display(httpResponse.error.message,
        'danger', 'center', 'top');
    } else {
      this.messageService.display('Something is wrong, Try later.',
        'danger', 'center', 'top');
    }
    return {};
  }
}
