import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-clic-form-title',
  templateUrl: './clic-form-title.component.html',
  styleUrls: ['./clic-form-title.component.css']
})
export class ClicFormTitleComponent implements OnInit {
  @Input() formTitle;
  constructor() { }

  ngOnInit() {
  }

}
