import {ErrorHandler, Injectable} from '@angular/core';


@Injectable()
export class ClientErrorHandler implements ErrorHandler {

  constructor() {
  }
  error: any;
  handleError(error: Error) {
    this.error = error;
    console.log(error);
   // router.navigate(['http://localhost:4200/error']);
  }
}
