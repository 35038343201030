import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TokenService } from '../../../../shared/services/token.service';
import { api } from '../../../../../config/api';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { client } from '../../../../../config/client';
import {ServerErrorHandlerService} from '../../../../shared/errors/server-error-handler.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;
  currentYear: number = (new Date()).getFullYear();

  message: string = null;
  client: any;
  // client: any;

  constructor(private fb: FormBuilder, private authService: AuthService,
              private serverErrorHandlingService: ServerErrorHandlerService,
              private router: Router) {
  }

  ngOnInit() {
    this.client = client;
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required])], password: [null, Validators.compose([Validators.required])]
    });
  }

  login() {
    const user = {
      email: this.form.get('email').value,
      password: this.form.get('password').value
    };

    this.authService.login(user).subscribe(
      response => {
        TokenService.storeToken(response['access_token']);

        this.authService.user = response['user'];
        this.router.navigate([client.DASHBOARD]);
      },
      error => this.message = 'Invalid email or password'
    );
  }

  me() {
    this.authService.post(api.ME).subscribe(
      response => console.log(response),
      error => this.message = this.serverErrorHandlingService.handleError(error)
    );
  }
}
