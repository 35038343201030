import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NgMaterialComponentsModule } from './modules/ng-material-components.module';
import { RouterModule } from '@angular/router';
import { PhoneInputComponent } from './components/phone-input/phone-input.component';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {TranslateSharedModule} from './translate/translate.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgDragDropModule } from 'ng-drag-drop';
import { DateFormatPipe } from './pipes/date-format/date-format';
import { DragDropService } from './services/drag-drop.service';
import { ClicImageComponent } from './components/clic-image/clic-image.component';
import { ClicImageV2Component } from './components/clic-image-v2/clic-image.component';
import { ClicVideoComponent } from './components/clic-video/clic-video.component';
import { MultiSearchSelectComponent } from './components/multi-search-select/multi-search-select.component';
import { SingleSearchSelectComponent } from './components/single-search-select/single-search-select.component';
import { ErrorsComponent } from './errors/errors.component';
import { FormInputBuilderComponent } from './components/form-input-builder/form-input-builder.component';
import { MccColorPickerModule } from 'material-community-components';
import { CarouselComponent } from '../modules/dashboard/pages/carousel/carousel.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ClicFileComponent } from './components/clic-file/clic-file.component';
import { TableRowActionsComponent } from './components/table/table-row-actions/table-row-actions.component';
import { TableContentComponent } from './components/table/table-content/table-content.component';
import { DragDropDirectiveModule } from 'angular4-drag-drop';
import { ClicInputComponent } from './components/clic-input/clic-input.component';
import { ClicFormTitleComponent } from './components/clic-form-title/clic-form-title.component';
import { ClicFormActionsComponent } from './components/clic-form-actions/clic-form-actions.component';
import { ClicDateInputComponent } from './components/clic-date-input/clic-date-input.component';
import { ImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImageUploadService } from './components/image-uploader/image-upload.service';
import { CaptionComponent } from './components/image-uploader/caption/caption.component';
import { PageFormComponent } from './components/page-form/page-form.component';
import { PageBuilderResolver } from './components/page-form/guards/page-builder-resolver';
import { QuillModule } from 'ngx-quill';
import { DialogsModule } from './components/dialogs/dialogs.module';
import {TranslateModule} from '@ngx-translate/core';
@NgModule({
  imports: [
    CommonModule,
    QuillModule,
    NgMaterialComponentsModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InternationalPhoneNumberModule,
    TranslateSharedModule,
    TranslateModule.forChild(),
    NgxMatSelectSearchModule,
    ImageCropperModule,
    NgDragDropModule,
    MccColorPickerModule,
    SlickCarouselModule,
    DragDropDirectiveModule,
    DialogsModule
  ],
  declarations: [
    ErrorsComponent,
    PageNotFoundComponent,
    SpinnerComponent,
    PhoneInputComponent,
    FormInputBuilderComponent,
    SingleSearchSelectComponent,
    MultiSearchSelectComponent,
    ClicImageComponent,
    ClicImageV2Component,
    ImageUploaderComponent,
    CaptionComponent,
    DateFormatPipe,
    ClicVideoComponent,
    ClicFileComponent,
    CarouselComponent,
    TableRowActionsComponent,
    TableContentComponent,
    ClicInputComponent,
    ClicFormTitleComponent,
    ClicFormActionsComponent,
    ClicDateInputComponent,
    PageFormComponent,
  ],
  exports: [
    NgMaterialComponentsModule,
    MccColorPickerModule,
    ErrorsComponent,
    PageNotFoundComponent,
    SpinnerComponent,
    PhoneInputComponent,
    FormInputBuilderComponent,
    SingleSearchSelectComponent,
    MultiSearchSelectComponent,
    ClicImageComponent,
    ClicImageV2Component,
    ImageUploaderComponent,
    CaptionComponent,
    ClicFileComponent,
    SlickCarouselModule,
    CarouselComponent,
    TableRowActionsComponent,
    TableContentComponent,
    ClicInputComponent,
    ClicFormTitleComponent,
    ClicFormActionsComponent,
    ClicDateInputComponent,
    DialogsModule,
    NgxMatSelectSearchModule,
    PageFormComponent
  ],
  providers: [DateFormatPipe, DragDropService, ImageUploadService, CaptionComponent, PageBuilderResolver],
  entryComponents: [
    ClicImageComponent,
    ClicImageV2Component,
    ClicVideoComponent,
    ClicFileComponent,
    ImageUploaderComponent,
    CaptionComponent
  ]
})
export class SharedModule {
}
