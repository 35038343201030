import { Component, OnInit } from '@angular/core';
import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import {MessageService} from '../../services/message.service';
import {MatDialogRef} from '@angular/material';
import {api} from '../../../../config/api';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-clic-file',
  templateUrl: './clic-file.component.html',
  styleUrls: ['./clic-file.component.css']
})
export class ClicFileComponent implements OnInit {


  fileChanged: any = null;
  progressValue: number;

  constructor(private http: HttpClient, private messageService: MessageService,
              public dialogRef: MatDialogRef<ClicFileComponent>) {
  }

  ngOnInit() {
  }

  fileChangeEvent(event: any): void {
    this.progressValue = 0;
    this.fileChanged = event;
  }

  onUpload() {
    if (!this.fileChanged || this.fileChanged.target.files.length === 0) {
      return;
    }
    const uploadData = new FormData();
    uploadData.append('file', this.fileChanged.target.files[0]);
    console.log(this.fileChanged);
    this.http.post(api.UPLOAD_FILE, uploadData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(
      map(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.progressValue = Math.round(100 * event.loaded / event.total);
        } else if (event instanceof HttpResponse) {
          this.dialogRef.close(event.body['data'].url);
        }
      })
    ).subscribe();
  }

}
