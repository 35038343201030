import { Component, Input, OnInit } from '@angular/core';
import { CarouselCard } from './carousel-card';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {

  @Input('slidesToShow') slidesToShow: number;
  @Input('slidesToScroll') slidesToScroll: number;
  @Input('data') data: CarouselCard[];

  slideConfig = {};

  constructor() {
  }

  ngOnInit(): void {
    this.slideConfig = { 'slidesToShow': this.slidesToShow, 'slidesToScroll': this.slidesToScroll };
  }

  addSlide() {
    // this.cards.push({ img: 'http://placehold.it/350x150/777777' });
  }

  removeSlide() {
    this.data.length = this.data.length - 1;
  }

  slickInit(e) {
    console.log('slick initialized');
  }

  breakpoint(e) {
    console.log('breakpoint');
  }

  afterChange(e) {
    console.log('afterChange');
  }

  beforeChange(e) {
    console.log('beforeChange');
  }

}
