import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-form-input-builder',
  templateUrl: './form-input-builder.component.html',
  styleUrls: ['./form-input-builder.component.css']
})
export class FormInputBuilderComponent implements OnInit {
  @Input('form') form;
  @Input('serverErrors') serverErrors;
  @Input('type') type;
  @Input('values') values;
  @Input('property') property;
  @Input('placeholder') placeholder;
  @Input('required') required;
  @Input('minLength') minLength;
  @Input('maxLength') maxLength;
  /**
   * Column Width
   */
  @Input('sm') sm;
  @Input('xs') xs;

  /**
   * Styles
   */
  @Input('cssClass') cssClass;
  @Input('divClass') divClass;

  hide = true;
  constructor() {
  }

  ngOnInit() {
  }

}
