import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-clic-input',
  templateUrl: './clic-input.component.html',
  styleUrls: ['./clic-input.component.css']
})
export class ClicInputComponent implements OnInit {
  @Input() property: any;
  @Input() form: any;
  @Input() label: any;
  @Input() required: any;
  @Input() serverErrors: any;
  @Input() type = 'input';
  @Input() labelSuffix = '';
  formControl;
  constructor() { }

  ngOnInit() {
    this.formControl = this.form.controls[this.property];
  }

}
