import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServerErrorHandlerService } from '../../errors/server-error-handler.service';
import { MatDialog } from '@angular/material';
import { MessageService } from '../../services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Page } from 'src/app/modules/pages/models/page';
import { PageService } from './page.service';
import { DateFormatPipe } from '../../pipes/date-format/date-format';
import { api } from 'src/config/api';

@Component({
  selector: 'app-page-form',
  templateUrl: './page-form.component.html',
  styleUrls: ['./page-form.component.css']
})
export class PageFormComponent implements OnInit {

  @Input() pageIdent;
  @Input() visibleFormFields;
  page: Page;
  form: FormGroup;
  serverErrors = {};
  isFieldRequired;
  nameProcessed = '';
  selectedParent = null;
  photoPath = api.mediaPath;
  mediaPath = api.mediaPath;
  uploadPhoto = api.UPLOAD_PHOTO;
  isNewPhoto = true;


  constructor(private fb: FormBuilder, private pageService: PageService,
    private serverErrorHandlerService: ServerErrorHandlerService, private dateFormat: DateFormatPipe,
    public dialog: MatDialog, private messageService: MessageService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.getPage(this.pageIdent);

  }

  getPage(pageIdent) {
    this.bindForm();
    this.isFieldRequired = this.pageService.builder.isRequiredField;

    this.pageService.getPageByIdent(pageIdent).subscribe(
      response => {
        this.page = response['data'];
        if (this.page.language) {
          this.page.language = this.page.language.id;
        }
        if (this.page.type) {
          this.page.type = this.page.type.id;
        }
        this.form.patchValue(this.page);
      },
      () => this.messageService.display(`Could not load Page, please try again`, 'danger', 'center', 'top')
    );
  }


  bindForm() {
    this.form = this.fb.group(this.pageService.builder['Page']);
    this.form.controls['publish_in_date'].setValue(true);
    if (this.form.controls['name']) {
      this.form.controls['name'].valueChanges.subscribe(value => {
        value = value || '';
        this.nameProcessed = value.replace(/[^a-zA-Z1-9 ]/g, '').replace(/ /g, '-');
        let url = this.nameProcessed.toString().toLowerCase();
        if (this.selectedParent !== null) {
          url = this.selectedParent['name'] + '-' + this.nameProcessed.toString().toLowerCase();
        }
        // if (this.form.controls['url']) {
        //   this.form.controls['url'].setValue(url);
        // }
        if (this.form.controls['meta_title']) {
          this.form.controls['meta_title'].setValue(value);
        }
      });
    }

    if (this.form.controls['language']) {
      this.form.controls['language'].setValue(this.pageService.builder['language_list'][0].id);
    }

  }

  edit() {
    const page = this.mapFormToPage();
    this.pageService.editPageByIdent(page, this.pageIdent).subscribe(
      () => {
        this.serverErrors = {};
        this.messageService.display('Page updated', 'megna');
      },
      error => this.serverErrors = this.serverErrorHandlerService.handleError(error)
    );
  }

  mapFormToPage() {
    return <Page>{
      name: this.form.get('name').value,
      parent_id: (this.form.get('parent_id').value) ? this.form.get('parent_id').value : 0,
      type: this.form.get('type').value,
      language: (this.form.get('language').value) ? this.form.get('language').value : 'en',
      url: this.form.get('url').value,
      heading: this.form.get('name').value,
      ident: this.pageIdent,
      content: this.form.get('content').value,
      meta_title: this.form.get('meta_title').value,
      meta_description: this.form.get('meta_description').value,
      publish_in_date: (this.form.get('publish_in_date').value) ? this.form.get('publish_in_date').value : false,
      publish_start_date: this.dateFormat.transform(this.form.get('publish_start_date').value),
      publish_end_date: this.dateFormat.transform(this.form.get('publish_end_date').value),
      active: (this.form.get('active').value) ? this.form.get('active').value : false,
      image: this.form.get('image').value,
      sort_order: 0
    };
    // this.form = this.fb.group(this.pagesService.builder[pagesSections.PAGE]);
  }

  getImage(image) {
    this.isNewPhoto = false;
    if (image.length > 0) {
      this.form.controls['image'].setValue(image[0].path);
    } else {
      this.form.controls['image'].setValue('');
    }
  }
}
