export const client = {
  LOGIN: '/auth/login',
  REFRESH: '/auth/refresh',
  ERROR: '/error',
  FORGOT: '/auth/forgot-password',
  DASHBOARD: '/dashboard',
  PROFILE: '/dashboard/profile',
  EDIT_PROFILE: '/dashboard/edit-profile',
  MEMBERS: '/dashboard/members',
  ADD_MEMBER: '/dashboard/members/add',
  DUPLICATE_MEMBER: '/dashboard/members/duplicate',
  MEMBER_PROFILE: '/dashboard/members/profile',
  SETTINGS: '/dashboard/settings',
  ADD_SETTINGS: '/dashboard/settings/add',
  EDIT_SETTINGS: '/dashboard/settings/edit',
  /**
   * PAGES
   */
  PAGES: '/dashboard/pages',
  ADD_PAGE: '/dashboard/pages/add',
  EDIT_PAGE: '/dashboard/pages/edit',
  EDIT_DRAFT_PAGE: '/dashboard/pages/edit-draft',
  DUPLICATE_PAGE: '/dashboard/pages/duplicate',
  /**
   * CAREER
   */
  CAREER: '/dashboard/career',
  ADD_CAREER: '/dashboard/career/add',
  EDIT_CAREER: '/dashboard/career/edit',
  EDIT_CAREER_DRAFT: '/dashboard/career/edit-draft',
  DUPLICATE_CAREER: '/dashboard/career/duplicate',

  /**
   * SLIDERS
   */
  SLIDERS: '/dashboard/sliders',
  ADD_SLIDER: '/dashboard/sliders/add',
  EDIT_SLIDER: '/dashboard/sliders/edit',
  EDIT_SLIDER_DRAFT: '/dashboard/sliders/edit-draft',
  DUPLICATE_SLIDER: '/dashboard/sliders/duplicate',

  /**
   * CATEGORIES
   */
  CATEGORIES: '/dashboard/catalog/categories',
  ADD_CATEGORY: '/dashboard/catalog/categories/add',
  EDIT_CATEGORY: '/dashboard/catalog/categories/edit',
  EDIT_DRAFT_CATEGORY: '/dashboard/catalog/categories/edit-draft',
  DUPLICATE_CATEGORY: '/dashboard/catalog/categories/duplicate',

  /**
   * ATTRIBUTES
   */
  ATTRIBUTES: '/dashboard/catalog/attributes',
  ADD_ATTRIBUTE: '/dashboard/catalog/attributes/add',
  EDIT_ATTRIBUTE: '/dashboard/catalog/attributes/edit',
  EDIT_DRAFT_ATTRIBUTE: '/dashboard/catalog/attributes/edit-draft',
  DUPLICATE_ATTRIBUTE: '/dashboard/catalog/attributes/duplicate',

  /**
   * BRANDS
   */
  BRANDS: '/dashboard/catalog/brands',
  ADD_BRAND: '/dashboard/catalog/brands/add',
  EDIT_BRAND: '/dashboard/catalog/brands/edit',
  EDIT_DRAFT_BRAND: '/dashboard/catalog/brands/edit-draft',
  DUPLICATE_BRAND: '/dashboard/catalog/brands/duplicate',

  /**
   * SUPPLIERS
   */
  SUPPLIERS: '/dashboard/catalog/suppliers',
  ADD_SUPPLIER: '/dashboard/catalog/suppliers/add',
  EDIT_SUPPLIER: '/dashboard/catalog/suppliers/edit',
  EDIT_DRAFT_SUPPLIER: '/dashboard/catalog/suppliers/edit-draft',
  DUPLICATE_SUPPLIER: '/dashboard/catalog/suppliers/duplicate',

  /**
   * TAGS
   */
  TAGS: '/dashboard/catalog/tags',
  ADD_TAG: '/dashboard/catalog/tags/add',
  EDIT_TAG: '/dashboard/catalog/tags/edit',
  EDIT_DRAFT_TAG: '/dashboard/catalog/tags/edit-draft',
  DUPLICATE_TAG: '/dashboard/catalog/tags/duplicate',

  /**
   * STORES
   */
  STORES: '/dashboard/catalog/stores',
  ADD_STORE: '/dashboard/catalog/stores/add',
  EDIT_STORE: '/dashboard/catalog/stores/edit',
  EDIT_DRAFT_STORE: '/dashboard/catalog/stores/edit-draft',
  DUPLICATE_STORE: '/dashboard/catalog/stores/duplicate',

  /**
   * PRODUCTS
   */
  PRODUCTS: '/dashboard/catalog/products',
  ADD_PRODUCT: '/dashboard/catalog/products/add',
  EDIT_PRODUCT: '/dashboard/catalog/products/edit',
  EDIT_DRAFT_PRODUCT: '/dashboard/catalog/products/edit-draft',
  DUPLICATE_PRODUCT: '/dashboard/catalog/products/duplicate',
  EDIT_VARIANT: '/dashboard/catalog/products/edit',
  ADD_VARIANT: '/dashboard/catalog/products/add-variant',

  /**
   * PRICE_SETTINGS
   */
  PRICE_SETTINGS: '/dashboard/catalog/price-settings',

  /**
   * ROOMS
   */
  ROOMS: '/dashboard/catalog/rooms/',
  ADD_ROOM: '/dashboard/catalog/rooms/add',
  EDIT_ROOM: '/dashboard/catalog/rooms/edit',
  EDIT_DRAFT_ROOM: '/dashboard/catalog/rooms/edit-draft',
  DUPLICATE_ROOM: '/dashboard/catalog/rooms/duplicate',

  /**
  * NEWS
  */
  NEWS: '/dashboard/news/',
  ADD_NEWS: '/dashboard/news/add',
  EDIT_NEWS: '/dashboard/news/edit',
  EDIT_DRAFT_NEWS: '/dashboard/news/edit-draft',
  DUPLICATE_NEWS: '/dashboard/news/duplicate',

  /**
  * MATERIALS
  */
  MATERIALS: '/dashboard/materials/materials',
  ADD_MATERIAL: '/dashboard/materials/materials/add',
  EDIT_MATERIAL: '/dashboard/materials/materials/edit',
  EDIT_DRAFT_MATERIAL: '/dashboard/materials/materials/edit-draft',
  DUPLICATE_MATERIAL: '/dashboard/materials/materials/duplicate',


  /**
  * MATERIALS CATEGORIES
  */
  MATERIALS_CATEGORIES: '/dashboard/materials/categories',
  ADD_MATERIAL_CATEGORIES: '/dashboard/materials/categories/add',
  EDIT_MATERIAL_CATEGORIES: '/dashboard/materials/categories/edit',
  EDIT_DRAFT_MATERIAL_CATEGORIES: '/dashboard/materials/categories/edit-draft',
  DUPLICATE_MATERIAL_CATEGORIES: '/dashboard/materials/categories/duplicate',

  /**
  * MATERIAL BRANDS
  */
  MAT_BRAND: '/dashboard/materials/brands',
  ADD_MAT_BRAND: '/dashboard/materials/brands/add',
  EDIT_MAT_BRAND: '/dashboard/materials/brands/edit',
  EDIT_DRAFT_MAT_BRAND: '/dashboard/materials/brands/edit-draft',
  DUPLICATE_MAT_BRAND: '/dashboard/materials/brands/duplicate',

  /**
  * MATERIAL TYPES
  */
  MAT_TYPE: '/dashboard/materials/types',
  ADD_MAT_TYPE: '/dashboard/materials/types/add',
  EDIT_MAT_TYPE: '/dashboard/materials/types/edit',
  EDIT_DRAFT_MAT_TYPE: '/dashboard/materials/types/edit-draft',
  DUPLICATE_MAT_TYPE: '/dashboard/materials/types/duplicate',

  /**
 * MATERIAL WOOD TYPES
 */
  MAT_WOOD_TYPE: '/dashboard/materials/woodTypes',
  ADD_MAT_WOOD_TYPE: '/dashboard/materials/woodTypes/add',
  EDIT_MAT_WOOD_TYPE: '/dashboard/materials/woodTypes/edit',
  EDIT_DRAFT_MAT_WOOD_TYPE: '/dashboard/materials/woodTypes/edit-draft',
  DUPLICATE_MAT_WOOD_TYPE: '/dashboard/materials/woodTypes/duplicate',

  /**
   * USERS
  */
  USERS: '/dashboard/uac/users',
  USERS_ADD: '/dashboard/uac/users/add',
  USERS_EDIT: '/dashboard/uac/users/edit',
  USERS_EDIT_DRAFT: '/dashboard/uac/users/edit-draft',
  USERS_DUPLICATE: '/dashboard/uac/users/duplicate',
  /**
   * ROLES
  */
  Roles: '/dashboard/uac/roles',
  Roles_add: '/dashboard/uac/roles/add',
  Roles_edit: '/dashboard/uac/roles/edit',
  Roles_edit_draft: '/dashboard/uac/roles/edit-draft',
  Roles_Duplicate: '/dashboard/uac/roles/duplicate',


  /**
   * PERMISSIONS
  */
  PERMISSIONS: '/dashboard/uac/permissions',

  /**
   * NAVIGATION
  */
  NAVIGATION: '/dashboard/navigation',


};
