import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { client } from '../../../../../config/client';
import { AuthService } from '../../services/auth.service';
import { api } from '../../../../../config/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MessageService } from 'src/app/shared/services/message.service';
import { TokenService } from 'src/app/shared/services/token.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  client: any;
  public form: FormGroup;
  message = '';
  token = null;
  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient, private messageService: MessageService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParamMap.get('token');
    if (!this.token) {
      this.router.navigate([client.LOGIN]);
    }

    this.client = client;
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, CustomValidators.email])],
      password: [null, Validators.compose([Validators.required, CustomValidators.password])]
    });
  }

  onSubmit() {
    const data = { email: this.form.get('email').value, password: this.form.get('password').value, token: this.token };
    this.http.post(api.RESET_PASSWORD, data).subscribe(
      response => {
        this.messageService.display('Password Changed Successfully', 'megna');
        this.router.navigate([client.LOGIN]);

      },
      error => {
        this.message = 'Invalid email or Link';
      });
  }

}
