import {Injectable} from '@angular/core';
import {BaseService} from '../../../shared/services/base.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {api} from '../../../../config/api';
import {User} from '../../uac/models/user';
import {Router} from '@angular/router';
import {client} from '../../../../config/client';
@Injectable()
export class AuthService extends BaseService {

  user: User;

  constructor(http: HttpClient, private router: Router) {
    super(http);
  }

  me(): Observable<User> {
    return this.post(api.ME);
  }

  login(user: any): Observable<{}> {
    return this.post(api.LOGIN, user);
  }

  getToken(): string {
    return localStorage.getItem('token');
  }

  logOut() {
    this.user = null;
    return this.http.post(api.LOGOUT, {});
  }

  updateProfile() {
    return this.post(api.USERS_UPDATE_PROFILE, this.user);
  }
}
