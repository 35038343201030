import { Pipe, PipeTransform } from '@angular/core';
import {DatePipe} from '@angular/common';

@Pipe({
  name: 'dateFormat',
})
export class DateFormatPipe extends DatePipe implements PipeTransform {
  /**
   * Takes a date and format it.
   */
  transform(value: any, ...args) {
    return super.transform(value, 'yyyy-MM-dd HH:mm:ss');
  }
}
