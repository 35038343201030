import { EventEmitter, Inject, Injectable } from '@angular/core';
import { TRANSLATIONS } from './translation'; // import our token
import { LANG_EN_DIR, LANG_EN_NAME } from './lang-en';
import { LANG_AR_DIR, LANG_AR_NAME } from './lang-ar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateSharedService {

  // inject our translations
  constructor(private translator: TranslateService) {
    // this.changeLanguage('en');
  }

  private currentLang: string;
  private currentDir: string;

  // private languageNames: string[] = [LANG_EN_NAME, LANG_AR_NAME];
  // private directionNames: string[] = ['ltr', 'rtl'];
  // private languageDirections: number[] = [LANG_EN_DIR, LANG_AR_DIR];



  public onLangChanged: EventEmitter<number> = new EventEmitter<number>();

  public getCurrentLang() {

    this.currentLang = localStorage.getItem('locale') !== undefined ? localStorage.getItem('locale') : 'en';
    return 'en';
    // return this.currentLang
  }






  changeLanguage(language) {

    const body = document.getElementsByTagName('body')[0];
    if (language !== 'ar') {
      // document.getElementsByTagName('html')[0].removeAttribute('dir');
      body.classList.remove('rtl');
      this.translator.use('en');
      this.currentDir = 'ltr';
      body.classList.add('ltr');

    } else if (language === 'ar') {
      // document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
      body.classList.remove('ltr');
      this.translator.use('ar');
      body.classList.add('rtl');
      this.currentDir = 'rtl';

    }

    if (language === undefined || language == null) {
      body.classList.remove('rtl');
      this.translator.use('en');
      this.currentDir = 'ltr';
      body.classList.add('ltr');
      language = 'en';
    }
    localStorage.setItem('locale', language);
    this.currentLang = language;

    console.log(this.currentLang);
  }
  // public selectLanguage(lang: number = -1) {
  //   if (lang === -1) {
  //     let item = localStorage.getItem('locale');
  //     if (item) {
  //       item = JSON.parse(item);
  //       this.use(item['lang']);
  //       return;
  //     } else {
  //       lang = this.EN;
  //     }
  //   }
  //   this.use(lang);
  //
  //   console.log(JSON.stringify({lang: lang, dir: this.languageDirections[lang]}));
  //
  //   localStorage.setItem('locale',
  //     JSON.stringify({lang: lang, dir: this.languageDirections[lang]}));
  //
  //   this.onLangChanged.emit(lang); // publish changes
  //
  // }

  public use(lang: string): void {
    // // set current language
    // this.currentLang = lang;
    // this.currentDir = this.languageDirections[lang];
  }

  private translate(key: string) {
    // // private perform translation
    // const translation = key;
    //
    // if (this._translations[this.currentLang] && this._translations[this.currentLang][key]) {
    //   return this._translations[this.currentLang][key];
    // }
    // return translation;
  }

  public instant(key: string) {
    // call translation
    // return this.translator(key);
  }
}
