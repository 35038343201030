import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-single-search-select',
  templateUrl: './single-search-select.component.html',
  styleUrls: ['./single-search-select.component.css']
})
export class SingleSearchSelectComponent implements OnInit {

  @Input('formControl') formControl;
  @Input('placeholder') placeholder;
  @Input('list') list;
  @Input('disabled') disabled = false;
  @Input('label') label;
  @Input('structured') structured;
  @ViewChild('singleSelect') singleSelect;
  @Output('selectionChange') selectionChange = new EventEmitter<boolean>();
  filterCtrl: FormControl = new FormControl();

  public filteredList$: ReplaySubject<any> = new ReplaySubject<any>(1);

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject();

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges) {

    // detect changes on list and update it

    if (changes && changes.list && changes.list.currentValue) {
      this.list = changes.list.currentValue;
      this.filteredList$.next(this.list);
    }
  }
  ngOnInit() {
    // load the initial list
    this.filteredList$.next(this.list);

    // listen for search field value changes
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterList();
      });

  }

  private filterList() {
    if (!this.list) {
      return;
    }
    // get the search keyword
    let search = this.filterCtrl.value;
    if (!search) {
      this.filteredList$.next(this.list.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.filteredList$.next(
      this.list.filter(item => item.name.toLowerCase().indexOf(search) > -1)
    );
  }

  select($event) {
    this.selectionChange.emit($event);
  }
}
