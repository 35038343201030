import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { TranslateSharedService } from '../translate/translate.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { colorSets } from '@swimlane/ngx-charts/release/utils';
import { client } from '../../../config/client';
import { api } from '../../../config/api';
import { AuthService } from '../../modules/auth/services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private translate: TranslateSharedService, public http: HttpClient, private authService: AuthService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(this.authService.getTokenExpirationDate(this.authService.getToken()));
    // if (this.authService.isTokenExpired()&& !request.url.includes('auth/me')) {
    //   // this.refreshToken();
    // }
    request = this.addHeaders(request);
    return next.handle(request).pipe(
      tap(response => {
        if (response instanceof HttpResponse && response.body['refresh_token']) {
          TokenService.storeToken(response.body['refresh_token']);
          return new HttpResponse({
            body: response.body['data'], headers: response.headers, status: response.status,
            statusText: response.statusText, url: response.url
          });
        }
      }, error => {

        if (error.status === 401 && !request.url.includes('auth/me') && !request.url.includes('auth/refresh') && !request.url.includes('reset-password')) {

          this.refreshToken(request);
        }
      })
    );
  }

  refreshToken(req) {
    const options = {
      headers: new HttpHeaders({
        'Authorization': `Bearer ${TokenService.getToken()}`,
      })
    };
    this.http.post(api.REFRESH, {}, options).subscribe(res => {
      TokenService.storeToken(res);
    }
      , error => {

        if (!req.url.includes('auth/me') || !req.url.includes('auth/reset-password') || !req.url.includes('auth/forget-password')) {
          this.router.navigate([client.LOGIN]);
        }
      }
    );
  }

  addHeaders(request: HttpRequest<any>) {
    if (!request.url.includes('auth/forget-password') || !request.url.includes('auth/reset-password')) {
      const headers = new HttpHeaders({
        'Authorization': `Bearer ${TokenService.getToken()}`,
        'Accept-Language': `${this.translate.getCurrentLang()}`
      });
      return request.clone({ headers });
    }
    const headers = new HttpHeaders({
      'Accept-Language': `${this.translate.getCurrentLang()}`
    });
    return request.clone({ headers });
  }
}
