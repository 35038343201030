import {Injectable} from '@angular/core';

@Injectable()
export class DragDropService {

  constructor() {
  }

  public moveItemsInArray(tableData, source, dest) {
    const sLeft = this.indexOf(tableData, source);
    const sRight = source['children'] ?  sLeft + source['children'] - 1 : sLeft;

    const dLeft = this.indexOf(tableData, dest);
    const dRight = dest['children'] ?  dLeft + dest['children'] - 1 : dLeft;

    console.log(sLeft, sRight, dLeft, dRight);
    const l1 = Math.min(sLeft, dLeft);
    const r1 = Math.min(sRight, dRight);

    const l2 = Math.max(sLeft, dLeft);
    const r2 = Math.max(sRight, dRight);

    console.log(l1, r1, l2, r2);
    const res = [];
    for (let i = 0; i < l1; i++) {
      res.push(tableData[i]);
    }
    for (let i = l2; i <= r2; i++) {
      res.push(tableData[i]);
    }
    for (let i = r1 + 1; i < l2; i++) {
      res.push(tableData[i]);
    }
    for (let i = l1; i <= r1; i++) {
      res.push(tableData[i]);
    }
    for (let i = r2 + 1; i < tableData.length; i++) {
      res.push(tableData[i]);
    }
    return res;
  }

  public indexOf(tableData, item) {
    for (let i = 0; i < tableData.length; i++) {
      if (item.id === tableData[i].id) {
        return i;
      }
    }
    return -1;
  }
}
