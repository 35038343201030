import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthRoutingModule } from './auth-routing.module';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NgMaterialComponentsModule } from '../../shared/modules/ng-material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthService } from './services/auth.service';
import { AuthLandingComponent } from './auth-landing.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    ReactiveFormsModule,
    NgMaterialComponentsModule,
    AuthRoutingModule
  ],
  declarations: [LoginComponent, ForgotPasswordComponent, AuthLandingComponent, RegisterComponent, ResetPasswordComponent],
  providers: [AuthService]
})
export class AuthModule {
}
