import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { client } from '../../../../../config/client';
import { AuthService } from '../../services/auth.service';
import { api } from '../../../../../config/api';
import { MessageService } from 'src/app/shared/services/message.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  client: any;
  public form: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService,  private messageService: MessageService) {}

  ngOnInit() {
    this.client = client;
    this.form = this.fb.group ( {
      email: [ null, Validators.compose( [ Validators.required, CustomValidators.email ] ) ]
    } );
  }

  onSubmit() {
    const data = { email: this.form.get('email').value };
    this.authService.post(api.FORGOT, data).subscribe(
      response => {
        this.messageService.display('Link has been sent to your E-mail', 'megna');
      },
      error => {
        this.messageService.display(error.error.message, 'danger');
      });
  }

}
