import { Component, OnInit, Input } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material';
import {APP_DATE_FORMATS, AppDateAdapter} from './clic-date-adapter';

@Component({
  selector: 'app-clic-date-input',
  templateUrl: './clic-date-input.component.html',
  styleUrls: ['./clic-date-input.component.css'],
  providers: [
    {
      provide: DateAdapter, useClass: AppDateAdapter
    },
    {
      provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS
    }
  ]
})
export class ClicDateInputComponent implements OnInit {
  @Input() property: any;
  @Input() form: any;
  @Input() label: any;
  @Input() serverErrors: any;
  @Input() labelSuffix = '';
  formControl;
  constructor() { }

  ngOnInit() {
    this.formControl = this.form.controls[this.property];
  }

  validateDateInput(event) {
    if (!event.value) {
      this.formControl.setValue(null);
    }
  }
}
